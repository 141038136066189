<template>
  <div>
    <div
      style="color: #2d3142"
      class="px-3"
    >
      <vs-card>
        <h2 class="mb-4">
          Gestão de indicadores por Owner
        </h2>

        <vs-row class="mb-4">
          <vs-col vs-lg="6">
            <p>Selecione o owner:</p>

            <vs-select
              v-model="selectedOwner"
              class="w-50"
            >
              <vs-select-item
                v-for="(owner, i) in owners"
                :key="i"
                :value="owner.id"
                :text="owner.id + ' - ' + owner.nickName"
              />
            </vs-select>
          </vs-col>
        </vs-row>

        <div
          v-for="(project, projIdx) in projects"
          :key="projIdx"
          class="w-100 px-4 py-4 mb-4"
          style="border: 1px solid #d8d8d8; border-radius: 10px"
        >
          <div class="d-flex align-items-center">
            <div class="mr-4">
              <img
                :src="imgpath(project.projectId)"
                width="45"
              >
            </div>

            <h3 class="text-uppercase">
              ({{ project.projectId }}) {{ project.projectName }}
            </h3>
          </div>

          <div :key="increment">
            <h4 class="mt-4">
              <vs-button
                class="mt-n1 mr-2"
                size="small"
                radius
                type="flat"
                :icon="
                  project.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                "
                @click="expand(project)"
              />
              Indicadores:
            </h4>

            <div v-if="project.expanded">
              <vs-table
                search
                stripe
                max-items="8"
                pagination
                :data="project.indicators"
              >
                <template slot="header">
                  <vs-button
                    class="mr-3"
                    icon="add"
                    size="small"
                    @click="createIndicator(project)"
                  >
                    Novo indicador
                  </vs-button>
                </template>

                <template slot="thead">
                  <vs-th>ID</vs-th>

                  <vs-th>Code</vs-th>

                  <vs-th>Descrição</vs-th>

                  <vs-th>Tipo</vs-th>

                  <vs-th>Porcentagem</vs-th>

                  <vs-th>Config</vs-th>

                  <vs-th>Ações</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr
                    v-for="(indicator, indIdx) in data"
                    :key="indIdx"
                  >
                    <vs-td>
                      {{ indicator.indId }}
                    </vs-td>

                    <vs-td>
                      {{ indicator.indCode }}
                    </vs-td>

                    <vs-td>
                      {{ indicator.description }}
                    </vs-td>

                    <vs-td>
                      {{ indicator.type }}
                    </vs-td>

                    <vs-td>
                      {{ indicator.percType }}
                    </vs-td>

                    <vs-td>
                      {{ indicator.config }}
                    </vs-td>

                    <vs-td>
                      <vs-button
                        size="small"
                        type="flat"
                        icon="edit"
                        radius
                        @click="editIndicator(indicator)"
                      />

                      <vs-button
                        size="small"
                        type="flat"
                        icon="autorenew"
                        radius
                        class="ml-2"
                        @click="calculateIndicator(project.projectId, indicator.indId)"
                      />
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </div>
      </vs-card>
    </div>

    <vs-popup
      title="Editar indicador"
      :active.sync="openIndicatorEdition"
    >
      <vs-row>
        <vs-col vs-lg="6">
          <label class="small font-weight-bold mt-2">Descrição:</label>
          <vs-input
            v-model="obj.description"
            class="w-100"
            :danger="invalid && !obj.description"
            danger-text="Campo obrigatório"
          />
        </vs-col>

        <vs-col vs-lg="6">
          <label class="small font-weight-bold mt-2">Config:</label>
          <vs-input
            v-model="obj.config"
            class="w-100"
            :danger="invalid && !obj.config"
            danger-text="Campo obrigatório"
          />
        </vs-col>

        <vs-col vs-lg="12">
          <label class="small font-weight-bold mt-2">
            Porcentagem:
          </label>

          <vs-select
            v-model="obj.percType"
            class="w-100"
            :danger="invalid && !obj.percType"
            danger-text="Campo obrigatório"
          >
            <vs-select-item
              v-for="(item, i) in percTypes"
              :key="i"
              :value="item.typeValue"
              :text="item.typeValue + ': ' + item.typeDesc"
            />
          </vs-select>
        </vs-col>

        <vs-col vs-lg="3">
          <label class="small font-weight-bold mt-2">
            ID:
          </label>

          <vs-input
            v-model="obj.indId"
            disabled
            class="w-100"
            :danger="invalid && !obj.indId"
            danger-text="Campo obrigatório"
          />
        </vs-col>

        <vs-col vs-lg="3">
          <label class="small font-weight-bold mt-2">
            Code:
          </label>

          <vs-input
            v-model="obj.indCode"
            disabled
            class="w-100"
            :danger="invalid && !obj.indCode"
            danger-text="Campo obrigatório"
          />
        </vs-col>

        <vs-col vs-lg="3">
          <label class="small font-weight-bold mt-2">
            Tipo:
          </label>

          <vs-input
            v-model="obj.type"
            disabled
            class="w-100"
            :danger="invalid && !obj.type"
            danger-text="Campo obrigatório"
          />
        </vs-col>
      </vs-row>

      <div class="d-flex justify-content-end mt-3">
        <vs-button
          class="mr-2"
          @click="openIndicatorEdition = false"
        >
          Cancelar
        </vs-button>

        <vs-button
          color="#66ff66"
          text-color="black"
          @click="postIndicator"
        >
          Salvar indicador
        </vs-button>
      </div>
    </vs-popup>

    <vs-popup
      title="Criar indicador"
      :active.sync="openIndicatorCreation"
      fullscreen
    >
      <h3>
        Bem vindo à criação de indicadores. Você deve selecionar o indicador
        modelo para copiar para o projeto selecionado. VocÊ poderá editar a
        descrição desse indicador posteriormente.
      </h3>

      <div class="d-flex">
        <vs-table
          v-if="avaliableGlobalIndicators.length > 0"
          class="w-50"
          max-items="10"
          pagination
          :data="avaliableGlobalIndicators"
          search
        >
          <template slot="header">
            <p class="my-4">
              Indicadores globais ausentes no projeto:
            </p>
          </template>

          <template slot="thead">
            <vs-th>ID</vs-th>

            <vs-th>Código</vs-th>

            <vs-th>Descrição</vs-th>

            <vs-th>Incluir</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              v-for="(indicator, i) in data"
              :key="i"
              class="border-top"
            >
              <vs-td class="py-1">
                {{ indicator.indId }}
              </vs-td>

              <vs-td class="py-1">
                {{ indicator.indCode }}
              </vs-td>

              <vs-td class="py-1">
                {{ indicator.description }}
              </vs-td>

              <vs-td class="py-1">
                <vs-button
                  size="small"
                  text-color="black"
                  color="secondary"
                  icon="add"
                  @click="addIndicatorToList(indicator)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <div
          class="w-50 d-flex flex-column justify-content-around align-items-center"
        >
          <h2 class="text-uppercase font-weight-bold">
            Indicadores selecionados:
          </h2>

          <h4 v-if="!indicatorsToCreate || !indicatorsToCreate.length">
            Nenhum indicador selecionado.
          </h4>

          <div
            v-else
            class="d-flex flex-wrap px-5 align-items-center"
          >
            <vs-chip
              v-for="(indicator, i) in indicatorsToCreate"
              :key="i"
              class="w-auto"
              :color="cor1"
              closable
              @click="removeIndicator(indicator)"
            >
              {{ indicator.indCode }} ({{ indicator.indId }})
            </vs-chip>
          </div>

          <vs-button
            size="large"
            @click="replicateIndicators"
          >
            Criar indicadores selecionados
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Config */
import { baseUrl, configDomain } from '@/global';

/* Services */
import axios from 'axios';

import Survey from '@/services/survey';
import System from '@/services/system';

const surveyService = new Survey();
const systemService = new System();

export default {
  name: 'SurveyIndicators',
  data: () => ({
    owners: [],
    globalIndicators: [],
    projects: [],
    selectedOwner: null,
    increment: 0,
    openIndicatorEdition: false,
    openIndicatorCreation: false,
    obj: {},
    selectedProject: {},
    indicatorsToCreate: [],
    invalid: false,
    percTypes: [
      {
        typeValue: 1,
        typeDesc: 'Relativa ao total do indicador',
      },
      {
        typeValue: 2,
        typeDesc: 'Relativa ao total de participantes da pesquisa',
      },
      {
        typeValue: 3,
        typeDesc: 'Relativa ao total de FanIds do projeto',
      },
      {
        typeValue: 4,
        typeDesc: 'Relativa ao total de respondentes da pergunta.',
      },
    ],
  }),

  computed: {
    cor1() {
      return this.$store.state.configBox.cor1;
    },
    avaliableGlobalIndicators() {
      if (!this.selectedProject.projectId) return [];

      let presentIndicators = this.selectedProject.indicators.map(
        (el) => el.indId
      );

      return this.globalIndicators.filter((el) => {
        return !presentIndicators.includes(el.indId);
      });
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  watch: {
    selectedOwner(newValue) {
      this.getProjects(newValue);
      this.selectedSurvey = null;
      this.surveyStruct = [];
      this.projects = [];
    },
  },
  mounted() {
    this.getOwners();

    this.getGlobalIndicators();
  },
  methods: {
    getOwners() {
      this.$store.dispatch('set_isLoadingActive', true);

      systemService
        .getOwners()
        .then((res) => {
          this.owners = res.data.data;

          this.$store.dispatch('set_isLoadingActive', false);
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);

          alert(err);
        });
    },
    getGlobalIndicators() {
      surveyService
        .getIndicators(0, this.token)
        .then((res) => (this.globalIndicators = res))
        .catch((err) => alert(err));
    },
    getProjects(owner) {
      this.$store.dispatch('set_isLoadingActive', true);

      const url = `${baseUrl}/indicators/GroupedByProject/${owner}`;

      axios
        .get(url)
        .then((res) => {
          this.projects = res.data.data;

          this.$store.dispatch('set_isLoadingActive', false);
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);

          alert(err);
        });
    },
    imgpath(id) {
      return `${configDomain}/prj/${id}/logo.png`;
    },
    expand(project) {
      if (project.expanded) {
        project.expanded = false;
      } else {
        project.expanded = true;
      }

      this.increment++;
    },
    editIndicator(indicator) {
      this.invalid = false;
      this.obj = indicator;
      this.openIndicatorEdition = true;
    },
    createIndicator(project) {
      this.selectedProject = project;
      this.indicatorsToCreate = [];
      this.openIndicatorCreation = true;
    },
    removeIndicator(indicator) {
      this.indicatorsToCreate = this.indicatorsToCreate.filter((el) => {
        return el.indId != indicator.indId;
      });
    },
    addIndicatorToList(indicator) {
      if (this.indicatorsToCreate.includes(indicator)) return;

      this.indicatorsToCreate.push(indicator);
    },
    postIndicator() {
      this.$store.dispatch('set_isLoadingActive', true);

      const indicator = this.obj;

      if (
        !indicator.description ||
        !indicator.indId ||
        !indicator.indCode ||
        !indicator.type ||
        !indicator.percType ||
        !indicator.config
      ) {
        this.$store.dispatch('set_isLoadingActive', false);

        this.invalid = true;

        return;
      }

      const url = `${baseUrl}/indicators/update`;

      axios
        .post(url, indicator)
        .then(() => {
          this.$store.dispatch('set_isLoadingActive', false);

          this.getProjects(this.selectedOwner);

          this.openIndicatorEdition = false;
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);

          alert(err);
        });
    },
    replicateIndicators() {
      this.$store.dispatch('set_isLoadingActive', true);

      const obj = {
        ProjectId: parseInt(this.selectedProject.projectId),
        Indicators: this.indicatorsToCreate,
      };

      const url = `${baseUrl}/indicators/replicateindicators`;

      axios
        .post(url, obj)
        .then(() => {
          this.$store.dispatch('set_isLoadingActive', false);

          this.getProjects(this.selectedOwner);

          this.openIndicatorCreation = false;
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);

          alert(err);
        });
    },
    calculateIndicator(projId, indId) {
      this.$store.dispatch('set_isLoadingActive', true);

      const url = `${baseUrl}/indicators/calculate/${projId}/${indId}`;

      axios
        .post(url)
        .then(() => {
          this.$store.dispatch('set_isLoadingActive', false);

          this.$vs.notify({
            color: 'rgb(94, 214, 90)',
            title: 'Sucesso',
            text: 'A procedure foi executada.',
          });
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);

          alert(err);
        });
    },
  },
};
</script>
